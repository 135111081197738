<template>
    <div>
        <div class="container pb-5">
            <SectionTitle title="Laboratorio de estados inmaduros"/><br>

            <div class = "text-center">
                <img class = "w-100" src="@/assets/manejoPlagas/labinmaduros/image-1.jpg" alt="">
            </div>

            <TitleText 
            title = "Laboratorio de identificación de larvas de mosca del Mediterráneo en Guatemala"
            text1 = "Cuando se desea conocer el establecimiento o niveles de infestación de mosca del Mediterráneo, se hace necesario utilizar la herramienta del labinmaduros de frutos para que el laboratorio de identificación de larvas reporte si en los frutos recolectados de un área específica, se detecta presencia o ausencia de mosca del Mediterráneo en estado de larva."
            text2 = "La diferenciación de especies de moscas de la fruta en estado de larva, es una técnica que conlleva una serie de procedimientos muy especializados, en donde expertos en el área tienen como primer paso darle el manejo adecuado a cada muestra de fruta que ingresa al laboratorio.  Posteriormente, se extraen del fruto las larvas que puedan estar infestándolo y mediante un fino estudio morfológico de los estados inmaduros de moscas de la fruta, pueda determinarse si los ejemplares de larva y/o pupa corresponden a mosca del Mediterráneo u otra especie de moscas de la fruta."
            numP = "2"
            />

            <div class = "text-center">
                <img class = "mt-4 w-100" src="@/assets/manejoPlagas/labinmaduros/image-2.jpg" alt="">
            </div>

            <ImagenTexto 
            title = "Metodología de identificación" 
            text1 = "El técnico del laboratorio encargado de la recepción de muestras de frutas provenientes del campo, verifica que el número de bolsas con muestras coincida con lo registrado en el reporte de campo. Posteriormente todas las muestras llevan una serie de procedimientos, tales como asignación de un número correlativo a la jaula de maduración de frutos, medir el peso en kilogramos, colocar un separador de frutos dentro de la jaula, conteo de frutos, agregar sustrato de maduración para larvas de tercer instar (arena de mar o vermiculita), cubrir la jaula con una tapa de manta o plástica según el tipo de fruto muestreado."
            img = "manejoPlagas/labinmaduros/image-4.jpg"
            imgsize = "4"
            textsize = "8"
            side = "right"
            />

            <p class = "mt-4">Cuando la muestra ha sido ingresada, se procede a seleccionar los frutos con mayor grado de madurez para hacer la primera disección; llamada técnicamente como disección inicial, que consiste en abrir los frutos y buscar las larvas dentro del mismo; al encontrar larvas, estas se colocan en un frasco con agua destilada. En esta actividad, el técnico laboratorista no realiza ninguna separación de la especie de larva, sino que introduce en el frasco todas las larvas que detecte en el fruto y coloca una etiqueta autoadhesiva con los datos más relevantes de campo.</p>

            <ImagenTexto 
            title = "Muestras de Frutos" 
            text1 = "Debido a las condiciones climáticas de Guatemala, las muestras de frutos se mantienen en observación durante una semana, para que los diferentes grados o instares larvales se desarrollen dentro del fruto, se coloca la jaula de maduración para que esta tenga ventilación y la larva no sufra estrés en su desarrollo. Durante este tiempo, se realizan dos disecciones más, una llamada disección intermedia y la última disección final."
            img = "manejoPlagas/labinmaduros/image-3.jpg"
            imgsize = "4"
            textsize = "8"
            side = "right"
            />

            <TitleText 
            title = "Características morfológicas de la larva de mosca del Mediterráneo"
            text1 = "Las larvas de mosca del Mediterráneo tienen una forma mucidiforme, es decir que tienen la parte posterior ensanchada y disminuyen gradualmente en forma cónica hacia la cabeza, son de color blanco o blanco amarillento. Pueden variar en longitud de 3 a 10 mm. Su cuerpo está compuesto por 11 segmentos fusiformes además de la cabeza, de los 11 segmentos, 3 corresponden a la región torácica y 8 al abdomen."
            numP = "1"
            />

            <ImagenTexto 
            title = "Muestras de Frutos" 
            text1 = "Debido a las condiciones climáticas de Guatemala, las muestras de frutos se mantienen en observación durante una semana, para que los diferentes grados o instares larvales se desarrollen dentro del fruto, se coloca la jaula de maduración para que esta tenga ventilación y la larva no sufra estrés en su desarrollo. Durante este tiempo, se realizan dos disecciones más, una llamada disección intermedia y la última disección final."
            textsize = "7"
            />

            <div class="text-center">
                <img class="img-fluid" src="@/assets/manejoPlagas/labinmaduros/image-5.jpg" width="40%" alt="Larva">  
            </div><br>

            <h5>Entre las características morfológicas más importantes para hacer una correcta identificación de larvas de Mosca del Mediterráneo están:  </h5>

            <p class = "mt-4">--- Los espiráculos anteriores que se encuentran en el primer segmento torácico de la larva, tienen una forma de guante en donde sobre salen unos tubos o dígitos posibles de contar y su número se frecuenta entre 7 y 11.</p>
            <p>--- En la cabeza está el aparato bucofaríngeo, el cual tiene entre 9 y 10 carinas bucales.</p>
            <p>--- En el segmento caudal se encuentran los espiráculos posteriores que tienen forma rectangular y los pelos o rayos son rectos.</p>
            <p>--- Estas características también se pueden observar en la pupa.</p>
            <p>--- Otras características como la forma de la antena, lóbulos anales, tubérculos sub-espiraculares, entre otras.</p>


        </div>

    </div>
</template>

<script>
import TitleText from "@/components/TitleText.vue";
import ImagenTexto from "@/components/ImagenTexto.vue";

export default {
    name: "Home",
    components: {
        TitleText,
        ImagenTexto,
    },
};
</script>